import React, {Component} from "react"
import styles from "../../css/search.module.css"
import {FaSearch} from "react-icons/fa"
import { createBrowserHistory } from 'history';
import { navigate } from 'gatsby';

class ZipCodeSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            zipcode: '',
            distance: 15,
            su: true,
            dt: true,
            mh: true,
            hh: true,
            ot: true,
            errors: []
        };
        this.history = typeof window !== 'undefined' ? createBrowserHistory({ forceRefresh: true }) : null;
        // this.history = createBrowserHistory({ forceRefresh: true });

        this.validateZipCodeOnBlur = this.validateZipCodeOnBlur.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }


    handleInputChange(event) {

        event.preventDefault();
        console.log(event.target.value)
        const query = event.target.value

       console.log('Thsi is teh query value'+ query + this.state.zipcode);

        navigate('/placesbyzipcode', {
                state: {
                        zipcode: this.state.zipcode,
                        distance: 15,
                        su: true,
                        dt: true,
                        mh: true,
                        hh: true,
                        ot: true
                    },
            replace: true
        });

        // this.history.push({
        //     pathname: '/placesbyzipcode',
        //     state: {
        //             zipcode: this.state.zipcode,
        //             distance: 15,
        //             su: true,
        //             dt: true,
        //             mh: true,
        //             hh: true,
        //             ot: true
        //         }
        //
        // });

    }

     validateZipCodeOnBlur(event){

        const zipcode = event.target.value;
        const errors = this.state.errors;
        if(errors.length != 0){
            errors.push(this.validateNotEmpty("zipcode", zipcode));
            this.setState({zipcode, errors});
        }
        else{
            this.setState({zipcode})
        }

     }

     validateNotEmpty(element, value){
       console.log(element, value);
    }

    displayErrors(){
        return (
            <div> {this.state.errors.map((err, i) =>
                <p key={ `err-${i}`}>{err}</p>)
            } </div>
        );
    }

     displayForm(){
         return (
             <section className={styles.searchBox}>
                 <input
                     className={styles.searchInput}
                     type="text"
                     aria-label="Search"
                     placeholder="Type 5 digit Zip Code ..." onBlur={this.validateZipCodeOnBlur}
                 ></input>
                 <button type="button" className={styles.logoBtn} onClick={this.handleInputChange}>
                     <FaSearch className={styles["logo-icon"]}/>
                 </button>
             </section>
         );
     }

    render(){

    return (
        <div>
            {this.displayErrors()}
            {this.displayForm()}
        </div>
        );
    }

}

// export default withRouter(ZipCodeSearch);
export default ZipCodeSearch
